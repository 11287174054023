html, body {
	padding: 0;
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	min-width: 380px;
}

img {
	max-width: 100%;
}

hr {
	height:1px;
	border: 0;
	background-color: #ddd;
	margin-bottom: 50px;
}

h1 {
	padding: 0;
	margin: 0 0 20px 0;
	font-size: 3em;
}

h2 {
	font-size: 2.3em;
	margin: 0 0 30px 0;
	text-align: center;
}

h3 {
	font-size: 1.8em;
	margin: 20px 0 20px 0;
}

p {
	font-size: 1.3em;
	line-height: 1.4em;
	color: #888;
}

nav {
	padding: 30px 0;
	svg {
		fill: $brand-color;
		height: 35px;
	}
}

section {
	padding: 60px 0;
}

form {
	display: flex;
	max-width: 500px;
	margin: 0 auto;

	input[type="text"] {
		flex-grow: 1;
		flex-shrink: 1;
		width: 100%;
		border: 1px solid #ddd;


		&, &:focus {
			border-top-left-radius: 30px;
			border-bottom-left-radius: 30px;
		}

		&:focus {
			box-shadow: 0 0 5px 0 $brand-color;
			outline: none;
		}
	}

	input[type="submit"] {
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;

		&:hover {
			background: darken($brand-color, 5%);
		}
	}
}

.button a, input[type="submit"], input[type="text"] {
	border: 0;
	padding: 15px 40px;
	box-sizing: content-box;
	font-size: 1em;
	font-weight: 100;
}

.button a, input[type="submit"] {
	background: $brand-color;
	color: #fff;
	cursor: pointer;
	font-weight: bold;
}

.button a {
	display: inline-block;
	text-decoration: none;
	border-radius: 30px;
	transition: 200ms ease-in background;

	&:hover {
		background: darken($brand-color, 5%);
	}
}
